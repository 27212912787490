<template>
  <div id="News">
    <!-- 未登录的导航 -->
    <div class="header" v-show="is_login" @quit_id="quit_id">
      <div class="logo">
        <img src="https://newstore.vynior.com/hthm1.png" alt="" />
      </div>

      <div class="fist_page" @click="back_home">
        <!-- <img src="https://newstore.vynior.com/hm4.png" alt="" />
         -->
        <span>首页</span>
      </div>

      <div class="right">
        <div class="language_en" :ckass="change_language != 0 ? 'change_language_en' : ''">
          <img src="https://newstore.vynior.com/hm5.png" alt="" m />
        </div>
        <div class="language_cn" :ckass="change_language != 0 ? 'change_language_en' : ''">
          <img src="https://newstore.vynior.com/hm6.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 登录后的导航 -->
    <Header v-show="is_logins"></Header>

    <div class="news_box">
      <!-- 面包屑 -->
      <div class="Breadcrumb">
        <span @click="back_home" class="Breadcrumb_fist_click"> 首页</span> /
        <span class="news_title">{{ new_news.text_title }}</span>
      </div>
      <!-- 新闻标题 -->
      <div class="News_Title">
        {{ new_news.text_title }}
      </div>
      <!-- 发布时间 -->
      <div class="News_time">发布时间:{{ new_news.year }}年{{ new_news.mon }}月{{ new_news.day }}日</div>
      <!-- 分割线 -->
      <div class="line"></div>
      <div class="News_content">
        <!-- <p class="fist_line">
          　
          灏图品测和多家企业与上海应用技术大学国际化妆品学院签署共同组建“化妆品人才培养联盟”（以下简称“联盟”）<br />协议。此举为响应贯彻落实上海市人民政府《关于推进上海美丽健康产业发展的若干意见》精神
        </p>
        <div class="News_img">
          <img src="https://newstore.vynior.com/news.png" alt="" />
        </div>

        <p class="second_line">
          　
          灏图品测自创立以来，一直将人才培养作为公司发展的关键工作。不仅每年都会为在校生/毕业生提供一些实习/就业<br />机会，并且还给予员工各方面的学习支持。
        </p> -->
        <div class="news" v-html="new_news.text_content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { HttpClient } from '@/utils/http';
import Header from '@/components/Header.vue';
export default {
  data() {
    return {
      new_news: [],
      is_data: {},
      is_login: 1,
      is_logins: 0,
      // 切换语言的样式控制
      change_language: 1,
      msg: '',
      year: '',
      mon: '',
      day: '',
    };
  },
  components: {
    Header,
  },
  methods: {
    back_home() {
      this.$router.push({ name: 'home' });
    },
    // 退出账号
    quit_id() {
      if (this.$route.path == '/home') {
        this.is_login = 1;
        this.is_logins = 0;
        this.email_val = '';
        this.password_val = '';
        // localStorage.clear();
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userid');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_email');
        localStorage.removeItem('token');
      } else {
        this.$router.push({ path: '/home' });
        this.is_login = 1;
        this.is_logins = 0;
        this.email_val = '';
        this.password_val = '';
        // localStorage.clear();
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userid');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_email');
        localStorage.removeItem('token');
      }
    },
    //请求新闻信息
    async req_newsDate() {
      await HttpClient.post('homeText/find_issue').then((res) => {
        console.log(res);
        this.is_data = res;
        console.log(this.is_data);
        this.year = this.is_data[2].updatedAt.slice(0, 4);
        console.log(this.year);
        this.mon = this.is_data[2].updatedAt.slice(5, 7);
        console.log(this.mon);
        this.day = this.is_data[2].updatedAt.slice(8, 10);
        console.log(this.day);
        console.log(this.is_data[2].text_content);
      });
    },

    get_img() {
      let w = document.querySelectorAll('p');
      console.log(w);
    },
  },
  mounted() {
    console.log(this.is_logins);
    // console.log(this.$route.params.title);
    // console.log(this.$route.params.change_num);
    // console.log(this.$route.params.change_num2);
    console.log(this.$route.params.content);
    this.is_login = this.$route.params.change_num;
    this.is_logins = !this.$route.params.change_num;
    // this.req_newsDate()
  },
  created() {
    this.new_news = this.$route.params.content;
    console.log(this.new_news);
  },
};
</script>

<style lang="less" scoped>
@import url('../../style/Home/News.less');
</style>
